<template>
    <div class="system-intro-page-container">
        <div class="intro-content-container">
            <div class="intro-header">
                <p class="mb-4 intro-welcome" style="font-weight: 300">
                    welcome
                </p>
                <p class="intro-sherzad">HERZAD</p>
                <p class="intro-miro">MIRO</p>
            </div>
            <img src="../../assets/imgs/logo/logo.png" alt="" />
        </div>
    </div>
</template>
<script>
export default {
    name: "SystemIntro",

    data() {
        return {};
    },

    mounted() {
        // if (sessionStorage.existShowSystemIntro) {
        // }
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
.system-intro-page-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c6e4f339;
    backdrop-filter: blur(5px);
    & img {
        animation: introImgAnimation 1.5s ease-in-out;
        width: 25vw;
        height: 25vw;
        border: 3px double #919191;
        box-shadow: 1px 2px 7px 10px white;
        border-radius: 50%;
        margin: 5px auto;
    }
}
.intro-header {
    direction: ltr;
    width: 100%;
    font-size: 24pt;
    font-weight: 800;
    padding: 20px;
    animation: introHeaderAnimation 1.5s ease-in-out;
}
.intro-welcome {
    animation: introWelcomeAnimation 1.5s ease-in-out;
    width: 15vw;
    color: #c3a602;
}
.intro-sherzad {
    position: relative;
}
.intro-sherzad,
.intro-miro {
    display: inline-block;
    margin-inline: 3px;
}
.intro-miro::first-letter {
    color: blue;
    // text-decoration: underline;
    // text-decoration-color: #e84428;
    // text-decoration-thickness: thin;
}
// .intro-sherzad::first-letter {
//     color: red;
//     font-size: 35pt;
//     text-shadow: 1px 2px 7px white;
//     transform: rotate(45deg);
//     display: inline-block;
// }
.intro-sherzad::before {
    content: "S"; /* Add an empty content to create a pseudo-element */
    color: red;
    font-size: 45pt;
    margin: 0px 20px;
    text-shadow: 1px 2px 7px white;
    transform: rotate(-20deg);
    display: inline-block;
    position: absolute;
    top: 0;
    left: -50px;
    font-family: monospace;
    animation: introImgAnimation 1.5s ease-in-out;
}
.intro-content-container {
    transform: translateY(-30px);
    transform: translateX(-5%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
@keyframes introImgAnimation {
    from {
        opacity: 0.3;
        max-width: 0vw;
    }
    to {
        max-width: 30vw;
        opacity: 1;
    }
}
@keyframes introHeaderAnimation {
    0% {
        opacity: 0.3;
        font-size: 1pt;
        text-shadow: 1px 2px 7px white;
        // border-width: 3px;
        padding: 90px;
    }
    50% {
        color: gold;
        font-size: 24pt;
    }
    100% {
        opacity: 1;
        font-size: 24pt;
    }
}
@keyframes introWelcomeAnimation {
    0% {
        opacity: 0;
        transform: translateX(400);
        color: whitesmoke;
    }
    50% {
        color: blue;
        font-size: 30pt;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
        font-size: 24pt;
        color: red;
    }
}
</style>
