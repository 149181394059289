var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aside',{staticClass:"app-main-aside",style:({ backgroundColor: _vm.chosenColor })},[_c('div',{staticClass:"app-aside-header-container"},[(!_vm.openedAside)?_c('div',{staticClass:"change-aside-color",on:{"click":function($event){_vm.showColorAside = !_vm.showColorAside}}},[_c('i',{staticClass:"fa-solid fa-palette mb-4 ms-0"})]):_vm._e(),(_vm.showColorAside && !_vm.openedAside)?_c('div',{class:_vm.lang == 'en'
                        ? 'show-colors-palets-en'
                        : 'show-colors-palets-ar'},[_c('div',{staticClass:"aside-color-close",on:{"click":function($event){return _vm.closeAsideBgColors()}}},[_vm._v(" x ")]),_c('div',{staticClass:"mb-1 text-decoration-underline p-2 pt-1 pb-0"},[_c('i',{staticClass:"fa-solid fa-paint-roller fa-flip",style:({ color: _vm.chosenColor })})]),_c('ul',[_c('li',[_c('button',{staticClass:"btn btn-change-color btn-change-color-blue",on:{"click":function($event){return _vm.changeAsideBgColor('aside-bg-color-blue')}}})]),_c('li',[_c('button',{staticClass:"btn btn-change-color btn-change-color-blue-2",on:{"click":function($event){return _vm.changeAsideBgColor('aside-bg-color-blue-2')}}})]),_c('li',[_c('button',{staticClass:"btn btn-change-color btn-change-color-black",on:{"click":function($event){return _vm.changeAsideBgColor('aside-bg-color-black')}}})]),_c('li',[_c('button',{staticClass:"btn btn-change-color btn-change-color-dark-black",on:{"click":function($event){return _vm.changeAsideBgColor(
                                    'aside-bg-color-dark-black'
                                )}}})]),_c('li',[_c('button',{staticClass:"btn btn-change-color btn-change-color-gold",on:{"click":function($event){return _vm.changeAsideBgColor('aside-bg-color-gold')}}})]),_c('li',[_c('button',{staticClass:"btn btn-change-color btn-change-color-light-blue",on:{"click":function($event){return _vm.changeAsideBgColor(
                                    'aside-bg-color-light-blue'
                                )}}})]),_c('label',{staticClass:"chose-color-label",style:({ color: _vm.chosenColor }),attrs:{"for":"select-color-input-id"}},[_c('i',{staticClass:"fa-solid fa-palette fa-spin"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedColorValue),expression:"selectedColorValue"}],staticClass:"select-main-color-input",attrs:{"id":"select-color-input-id","type":"color"},domProps:{"value":(_vm.selectedColorValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.selectedColorValue=$event.target.value},_vm.updateSelectedColor]}})])]):_vm._e(),_c('router-link',{staticClass:"bg-transparent",attrs:{"to":"/"}},[_c('img',{staticClass:"app-aside-logo",attrs:{"width":"60","src":require("@/assets/imgs/logo/logo.png"),"alt":""}}),_c('router-link',{staticClass:"bg-transparent",attrs:{"to":"/"}},[(!_vm.openedAside)?_c('div',{staticClass:"app-aside-project-name"},[_vm._v(" "+_vm._s(_vm.$t("projectName"))+" ")]):_vm._e()])],1)],1),_c('div',{staticClass:"aside-content-container"},[_c('AsideTabComponents',{attrs:{"openedAside":_vm.openedAside,"asideTabTitle":this.$t('asideTabs.contacts'),"asideTabIconClass":'fa-regular fa-address-book',"tabChildData":_vm.contactsChildData}})],1),_c('div',{staticClass:"aside-content-container"},[_c('AsideTabComponents',{attrs:{"openedAside":_vm.openedAside,"asideTabTitle":this.$t('asideTabs.units'),"asideTabIconClass":'fa-solid fa-hotel',"tabChildData":_vm.contactsChildDataPlaces}})],1),_c('div',{staticClass:"aside-content-container"},[_c('AsideTabComponents',{attrs:{"openedAside":_vm.openedAside,"asideTabTitle":this.$t('asideTabs.invoices'),"asideTabIconClass":'fa-solid fa-sack-dollar',"tabChildData":_vm.contactsChildDataExpenses}})],1),_c('div',{staticClass:"aside-content-container"},[_c('AsideTabComponents',{attrs:{"openedAside":_vm.openedAside,"asideTabTitle":this.$t('cheques.cheques'),"asideTabIconClass":'fa-solid fa-money-check-dollar',"tabChildData":_vm.cheques}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }